/* Import Tailwind CSS */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Define custom font */
@font-face {
  font-family: 'Actor';
  src: url('./fonts/Actor-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

html, body {
  max-width: 100%;
  overflow-x: hidden;
}

/* Apply custom font globally and remove bold styling */
body, h1, h2, h3, h4, h5, h6, p, a, span, div, input, button {
  font-family: 'Actor', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-weight: normal;  /* Ensure no text is bolded by default */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  font-weight: normal;  /* Ensure no code text is bolded by default */
}